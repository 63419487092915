export const ON_CHANGE_DEBOUNCE_TIMER = 300;

export const PUB_SUB_EVENTS = {
  cartUpdate: 'cart-update',
  quantityUpdate: 'quantity-update',
  variantChange: 'variant-change',
  cartError: 'cart-error',
};

const headerHeight = () => {
  const header = document.querySelector('header');
  const announcementBar = document.querySelector('#announcement-bar');
  let height = 80;
  if(header && announcementBar) {
    height = header.clientHeight + announcementBar.clientHeight
  } else if(header && !announcementBar) {
    height = header.clientHeight;
  }
  console.log(height);
  document.documentElement.style.setProperty('--header-height', height + "px"  );
  console.log(header.clientHeight, announcementBar);
}

headerHeight();

